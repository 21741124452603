import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Survey, vote } from "../../api/faction"

interface useVoteParams {
    gameId: number
    surveyId: number
    choiceId?: number
    invalidateOnVote?: boolean
}

export const useVote = ({ gameId, surveyId, choiceId, invalidateOnVote = false }: useVoteParams) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ id }: { id?: number }) => vote(gameId, surveyId, (id || choiceId)!),
        onSuccess: (data, id) => {

            if (invalidateOnVote) {
                queryClient.invalidateQueries({ queryKey: ["surveys", gameId] })
            }
            else {
                // optimisic update to update the "voted" property
                // other stats (like votes count) will be updated by the faction socket
                queryClient.setQueryData(["surveys", gameId], (oldData: Survey[] | undefined) => {
                    // find relevant survey and update it
                    const survey = oldData?.find((s) => s.id == surveyId)
                    if (survey) {
                        return oldData?.map((s) => {
                            if (s.id == surveyId) {
                                return {
                                    ...s,
                                    voted: id || choiceId,
                                }
                            }
                            return s
                        })
                    }
                })
            }
        },
        onError(error, variables, context) {
            queryClient.invalidateQueries({ queryKey: ["surveys", gameId] })
        },
    })
}
