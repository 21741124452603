import { Box, Heading, Input, SimpleGrid, Stack, Text } from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent, createRef, useState } from "react"
import { Survey as SurveyInterface, getSurveys, useSurveys, vote } from "../../api/faction"
import { useGameId, usePlayer } from "../../hooks/query"
import Choice from "./Choice"
import { searchUser } from "../../api/player"
import Loading from "../common/Loading"
import { useOutsideClick } from "@chakra-ui/react"
import Panel from "../common/Panel"
import { useVote } from "./hooks"
import MapChoice from "./MapChoice"

interface SurveyProps extends SurveyInterface {}
const Survey: FunctionComponent<SurveyProps> = ({ id, survey_type, choices, voted, pick_users }) => {
    const gameId = useGameId()
    const queryClient = useQueryClient()
    const [querySearch, setQuerySearch] = useState("")
    const player = usePlayer()
    const faction = player?.faction
    const { data: players, isLoading: isPendingSearch } = useQuery({
        queryKey: ["players", gameId, querySearch, faction],
        queryFn: () => searchUser({ gameId, querySearch, faction }),
        enabled: querySearch.length > 2 && pick_users,
    })
    const searchBox = createRef<HTMLDivElement>()
    useOutsideClick({
        ref: searchBox,
        handler: () => setQuerySearch(""),
    })

    const voteMutation = useVote({ gameId, surveyId: id, invalidateOnVote: survey_type === "Map" })
    const sortedChoices =
        survey_type == "Map" ? choices.sort((a, b) => b.id - a.id) : choices.sort((a, b) => b.votes - a.votes)
    const totalVotes = sortedChoices.reduce((acc, choice) => acc + choice.votes, 0)
    const filteredPlayers = players?.filter((p) => p.id != player?.userId)

    return (
        <Panel>
            <Heading as={"h3"} fontSize="lg">
                {" "}
                {survey_type}
            </Heading>

            {survey_type === "Politic system" && (
                <Text variant="discreet">
                    This is your faction name, for example "Red kingdom". In the future, different politic system may
                    add bonuses to your faction.
                </Text>
            )}
            {survey_type === "Leader" && <Text variant="discreet">Elect your leader.</Text>}
            {survey_type === "General" && (
                <Text variant="discreet">
                    Elect your generals. In the future, general will be able to add hints on the map.
                </Text>
            )}
            {survey_type === "Diplomat" && (
                <Text variant="discreet">Elect your diplomats. Diplomats forge alliance with other factions.</Text>
            )}
            {survey_type === "Map" && <Text variant="discreet">Vote for the map you wish to play.</Text>}
            {survey_type !== "Map" && <Text variant="discreet">3 votes minimum.</Text>}

            {pick_users && (
                <form onSubmit={(e) => e.preventDefault()}>
                    <Text variant="discreet">
                        You can vote for a player in the list or vote for a new player by searching it.
                    </Text>

                    <Input
                        placeholder="Search"
                        name="search"
                        value={querySearch}
                        onChange={(e) => {
                            setQuerySearch(e.target.value)
                        }}
                        size="sm"
                        mt="2"
                    />
                    <Box position="relative" ref={searchBox}>
                        {querySearch.length > 2 && (
                            <Box position="absolute" top="0" left="0" bgColor="orange.800" p="1" zIndex="10">
                                {isPendingSearch && <Loading size="xs" />}
                                {filteredPlayers &&
                                    filteredPlayers.map((player) => (
                                        <Text
                                            _hover={{ bgColor: "orange.700" }}
                                            p="1"
                                            onClick={() => voteMutation.mutate({ id: player.id })}
                                            key={player.id}
                                        >
                                            {player.username}
                                        </Text>
                                    ))}
                                {filteredPlayers && filteredPlayers.length === 0 && <Text>No players found.</Text>}
                            </Box>
                        )}
                    </Box>
                </form>
            )}

            {survey_type === "Map" && (
                <SimpleGrid mt="2" columns={[1, 1, 3]}>
                    {sortedChoices.map((choice) => (
                        <MapChoice
                            key={choice.id}
                            surveyId={id}
                            totalVotes={totalVotes}
                            {...choice}
                            voted={voted === choice.id}
                            pickUsers={pick_users}
                        />
                    ))}
                </SimpleGrid>
            )}
            {survey_type !== "Map" && (
                <Stack mt="2" gap="1">
                    {sortedChoices.map((choice) => (
                        <Choice
                            key={choice.id}
                            surveyId={id}
                            surveyType={survey_type}
                            totalVotes={totalVotes}
                            {...choice}
                            voted={voted === choice.id}
                            pickUsers={pick_users}
                        />
                    ))}
                </Stack>
            )}
        </Panel>
    )
}

export default Survey
