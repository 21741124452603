import { Config } from "../../../api/game";
import { FactionTileInfo } from "../../../api/world";
import { WORLD_SIZE } from "../../../config";
import { queryClient } from "../../../query_client";
import RootStore from "../../../store/RootStore";
import { Factions } from "../../../types";
import { TILE_SIZE } from "../config";
import { GameScene } from "../scene/world";

interface PingConstructor {
    scene: GameScene
    rootStore: RootStore
}
interface FactionSocketData {
    type: 'ping'
    x: number
    y: number
    ping_type: "attack" | "defend"
}

/** hande walls info */
export class Pings {

    scene: GameScene
    rootStore: RootStore
    pingLayer: Phaser.GameObjects.Layer
    world: FactionTileInfo[][]

    constructor({ scene, rootStore }: PingConstructor) {
        this.scene = scene
        this.rootStore = rootStore
        this.pingLayer = scene.add.layer().setDepth(100)


        rootStore.socketStore.factionSocket?.addEventListener("message", (event) => {
            const data: FactionSocketData = JSON.parse(event.data)
            if (data.type === 'ping') {
                this.addPing(data.x, data.y, data.ping_type)
            }
        })
    }


    addPing(x: number, y: number, ping_type: "attack" | "defend") {
        const ping = this.scene.add.sprite(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2, ping_type).setScale(1);
        ping.alpha = 0
        ping.scale = 3
        // animate sprite with shake effect
        this.scene.tweens.add({
            targets: ping,
            alpha: 1,
            ease: 'power3',
            scale: 1.5,
            duration: 200,
        })

        // this.scene.tweens.add({
        //     targets: ping,
        //     yoyo: true,
        //     angle: { start: 20, to: -20 },
        //     repeat: -1,
        //     ease: 'Sine.easeInOut',
        //     // rotation: 
        //     duration: 200,

        // })

        const chain = this.scene.tweens.chain({
            targets: ping,
            tweens: [
                {
                    yoyo: true,
                    angle: { start: 10, to: -10 },
                    repeat: 5,
                    // scale: 1.2,
                    ease: 'Sine.easeInOut',
                    duration: 100
                },
                {
                    angle: 0,
                    // scale: 1,
                    ease: 'Sine.easeInOut',
                    duration: 100
                },
                {
                    angle: 0,
                    // scale: 1,
                    duration: 2000
                },



            ],
            repeat: 8,
            onComplete: () => {
                this.scene.tweens.add({
                    targets: ping,
                    alpha: 0,
                    ease: 'power3',
                    scale: 0,
                    duration: 1000,
                    onComplete: () => {
                        ping.destroy()
                    }
                })
            }
        });



        // this.scene.tweens.add({
        //     targets: ping,
        //     scale: 1.5,
        //     ease: 'Linear',
        //     duration: 1000,
        //     repeat: 0,
        //     yoyo: false,
        //     onComplete: () => {
        //         ping.destroy()
        //     }
        // });
        this.pingLayer.add(ping)
    }
}

