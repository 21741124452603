import { Config } from "../../../api/game";
import { API_BASE_URL } from "../../../config";
import { rootStore } from "../../../store";
import { Factions } from "../../../types";
import { getKeys } from "../../../utils";
import { MOBILE_BREAKPOINT, TILE_SIZE } from "../config";
import { Flags } from "../objects/flags";
import { Invasion } from "../objects/invasion";
import { Pings } from "../objects/pings";
import { UISelect } from "../objects/uiselect";

// import AnimatedTiles from 'phaser-animated-tiles-phaser3.5/dist/AnimatedTiles.min.js';

const MAP_MOVE_DELAY = 70
const MAP_MOVE_TRESHOLD = 100
export class GameScene extends Phaser.Scene {

    gameId: number
    map: Phaser.Tilemaps.Tilemap
    invasion: Invasion
    ping: Pings
    private controls: Phaser.Cameras.Controls.FixedKeyControl
    mapStore = rootStore.mapStore
    /** is the user dragging the map ? */
    isDragging: boolean = false
    cursorKeys: Phaser.Types.Input.Keyboard.CursorKeys
    cursorKeyDownDuration: number = 0

    constructor() {
        super({
            key: 'GameScene'
        });

    }

    preload() {
        this.gameId = rootStore.userStore.gameId!
        this.load.tilemapTiledJSON("tilemap", `${API_BASE_URL}api/game/${this.gameId}/world/tilemap?game_id=${this.gameId}`)
        // this.load.image("tiles_image", "map/tileset.png")
        this.load.image("tileset_image", "/map/tileset-extruded.png")
        this.load.image("objects_image", "/map/objects-extruded.png")

        // pings
        this.load.image("attack", "/icons/attack.png")
        this.load.image("defend", "/icons/defend.png")

        
        // this.load.image("misc32", "/map/misc32.png")

        this.load.bitmapFont('retrogaming', '/fonts/retrogaming.png', '/fonts/retrogaming.xml')

        // this.load.scenePlugin({
        //     key: 'animatedTiles',
        //     url: AnimatedTiles,
        //     sceneKey: 'GameScene'
        // }
        // );

        // ui select 
        this.load.spritesheet('uiselect', '/map/uiselect.png', { frameWidth: TILE_SIZE, frameHeight: TILE_SIZE });

        for (let faction of getKeys(Factions)) {
            if (faction === Factions.NEUTRAL)
                continue
            this.load.spritesheet(`guardian_${faction}`, `/icons/guardian_${faction}.png`, { frameWidth: 16, frameHeight: 16 });
            this.load.spritesheet(`knight_${faction}`, `/icons/knight_${faction}.png`, { frameWidth: 32, frameHeight: 32 });
        }
        this.load.spritesheet(`orc`, `/icons/orc.png`, { frameWidth: 16, frameHeight: 16 });
        this.load.spritesheet(`flags`, `/map/flags.png`, { frameWidth: 16, frameHeight: 16 });
        this.load.spritesheet(`misc`, `/map/misc.png`, { frameWidth: 16, frameHeight: 16 });
        this.load.spritesheet(`misc32`, `/map/misc32.png`, { frameWidth: 32, frameHeight: 32 });
        // this.load.spritesheet("tiles", "map/tileset.png", { frameWidth: 16, frameHeight: 16 })
        // this.load.setBaseURL('http://labs.phaser.io');

        // this.load.image('sky', 'assets/skies/space3.png');
        // this.load.image('logo', 'assets/sprites/phaser3-logo.png');
        // this.load.image('red', 'assets/particles/red.png');
    }

    create() {

        this.map = this.make.tilemap({
            key: "tilemap",
            tileWidth: TILE_SIZE,
            tileHeight: TILE_SIZE,
        })
        // const tileset = this.map.addTilesetImage("tileset", "tiles_image")
        const tileset = this.map.addTilesetImage("tileset", "tileset_image", TILE_SIZE, TILE_SIZE, 1, 2)
        const objectsTileset = this.map.addTilesetImage("objects", "objects_image", TILE_SIZE, TILE_SIZE, 1, 2)

        // const waterLayer = this.map.createLayer("water", waterTileset) //.setPipeline('Light2D');
        const topLayer = this.map.createLayer("background", objectsTileset!)
        this.map.createLayer("terrains", tileset!) //.setPipeline('Light2D');
        this.map.createLayer("objects", objectsTileset!)
        // const flagsLayer = this.map.createLayer("flags", flagsTileset!)


        // this.sys.animatedTiles.init(this.map);


        // to show HQs
        const hqLayer = this.map.createBlankLayer("hq-layer", [tileset!, objectsTileset!]!)

        var cursors = this.input.keyboard!.createCursorKeys();
        var controlConfig = {
            camera: this.cameras.main,
            left: cursors.left,
            right: cursors.right,
            up: cursors.up,
            down: cursors.down,
            speed: 0.5
        };
        this.controls = new Phaser.Cameras.Controls.FixedKeyControl(controlConfig);

        // @ts-ignore
        var pinch = this.rexGestures.add.pinch({ enable: true })

        const camera = this.cameras.main
        camera.roundPixels = true
        let cameraHeight = 1600
        if (window.innerWidth <= MOBILE_BREAKPOINT)
            cameraHeight = 1900
        camera.setBounds(0, 0, 1600, cameraHeight)
        // default camera to center
        camera.pan(800, 800, 0)
        // camera.setZoom(3, 3)


        pinch
            .on("drag1", (dragScale: any) => {
                var drag1Vector = dragScale.drag1Vector
                camera.scrollX -= drag1Vector.x / camera.zoom
                camera.scrollY -= drag1Vector.y / camera.zoom
                this.isDragging = Math.abs(drag1Vector.x) + Math.abs(drag1Vector.y) > 0
            })
            .on("drag1end", () => {
                this.isDragging = false
            })
            .on("pinch", (dragScale: any) => {
                this.isDragging = true
                var scaleFactor = dragScale.scaleFactor
                camera.zoom *= scaleFactor
                camera.zoom = Math.min(2, Math.max(0.5, camera.zoom))
            })
        // this.cameras.main.setBounds(0, 0, 1024, 1024);

        // this.cameras.main.setZoom(1);
        // this.cameras.main.centerOn(0, 0);

        this.input.on(
            "wheel",
            (pointer: PointerEvent, gameObjects: any, deltaX: number, deltaY: number, deltaZ: number) => {
                // console.log(
                //     this.input.mousePointer.x,
                //     this.input.mousePointer.y
                // )
                var worldPoint = this.input.activePointer.positionToCamera(
                    this.cameras.main,
                    Phaser.Math.Vector2
                ) as Phaser.Math.Vector2

                var pointerTileX = this.map.worldToTileX(worldPoint.x)
                var pointerTileY = this.map.worldToTileY(worldPoint.y)

                // zoom out 
                if (deltaY > 0) {
                    var newZoom = Math.max(0.7, camera.zoom * 0.7)
                    if (newZoom > 0.5) {
                        camera.zoomTo(newZoom, 100)
                        camera.pan(
                            pointerTileX! * TILE_SIZE,
                            pointerTileY! * TILE_SIZE,
                            100, 'Power2'
                        )
                    }
                }

                // zoom in
                if (deltaY < 0) {
                    var newZoom = camera.zoom * 1.3
                    if (newZoom < 2) {
                        camera.zoomTo(newZoom, 50)
                        camera.pan(
                            pointerTileX! * TILE_SIZE,
                            pointerTileY! * TILE_SIZE,
                            50
                        )
                    }
                }

                // cam.pan(500, 500, 2000, 'Power2');
                // camera.zoomTo(4, 3000);
            },
            this
        )

        this.cursorKeys = this.input.keyboard!.createCursorKeys();
        // handle move
        this.cursorKeys.up.on("down", () => {
            this.cursorKeyDownDuration = 0
            this.mapStore.move(0, -1)
        })
        this.cursorKeys.down.on("down", () => {
            this.cursorKeyDownDuration = 0
            this.mapStore.move(0, 1)
        })
        this.cursorKeys.left.on("down", () => {
            this.cursorKeyDownDuration = 0
            this.mapStore.move(-1, 0)
        })
        this.cursorKeys.right.on("down", () => {
            this.cursorKeyDownDuration = 0
            this.mapStore.move(1, 0)
        })




        new UISelect({ scene: this, layer: topLayer! })
        new Flags({ scene: this, layer: topLayer! })

        this.invasion = new Invasion({ scene: this })

        this.ping = new Pings({ scene: this, rootStore: rootStore })


        /** Display HQS */
        this.game.events.once('config', (config: Config) => {
            const faction = rootStore.userStore.player?.faction
            for (const key of getKeys(config.hqs)) {
                const hq = config.hqs[key]
                this.map.putTileAt(555, hq.x, hq.y, true, hqLayer!);

                // pan to player HQ
                if (key === faction)
                    camera.pan(hq.x * TILE_SIZE, hq.y * TILE_SIZE, 500, Phaser.Math.Easing.Quadratic.Out)
            }

        }, this.game);


        setTimeout(() => this.mapStore.setBooted(true), 1)
    }

    update(time: number, delta: number): void {
        // only move when the cursor is next to boundaries
        const selectedX = this.mapStore.selectedTile && this.mapStore.selectedTile.x * TILE_SIZE || 500
        const selectedY = this.mapStore.selectedTile && this.mapStore.selectedTile.y * TILE_SIZE || 500
        if ((this.cursorKeys.down.isDown && selectedY > this.cameras.main.worldView.bottom - MAP_MOVE_TRESHOLD) ||
            (this.cursorKeys.up.isDown && selectedY < this.cameras.main.worldView.top + MAP_MOVE_TRESHOLD) ||
            (this.cursorKeys.left.isDown && selectedX < this.cameras.main.worldView.left + MAP_MOVE_TRESHOLD) ||
            (this.cursorKeys.right.isDown && selectedX > this.cameras.main.worldView.right - MAP_MOVE_TRESHOLD)) {
            this.controls.update(delta);
        }

        // handle cursor move
        // move map when cursor key are pressed
        // move every xms as long as the key is pressed
        if (this.cursorKeys.down.isDown)
            this.cursorKeyDownDuration += delta
        else if (this.cursorKeys.up.isDown)
            this.cursorKeyDownDuration += delta
        else if (this.cursorKeys.left.isDown)
            this.cursorKeyDownDuration += delta
        else if (this.cursorKeys.right.isDown)
            this.cursorKeyDownDuration += delta

        let moved = false
        if (this.cursorKeys.up.isDown && this.cursorKeyDownDuration > MAP_MOVE_DELAY) {
            moved = true
            this.mapStore.move(0, -1)
        }
        if (this.cursorKeys.down.isDown && this.cursorKeyDownDuration > MAP_MOVE_DELAY) {
            moved = true
            this.mapStore.move(0, 1)
        }
        if (this.cursorKeys.left.isDown && this.cursorKeyDownDuration > MAP_MOVE_DELAY) {
            moved = true
            this.mapStore.move(-1, 0)
        }
        if (this.cursorKeys.right.isDown && this.cursorKeyDownDuration > MAP_MOVE_DELAY) {
            moved = true
            this.mapStore.move(1, 0)
        }
        if (moved)
            this.cursorKeyDownDuration = 0


    }


}
