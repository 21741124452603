import { SimpleGrid } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { LeaderRole } from "../../api/faction"
import { useFactions, useGameId } from "../../hooks/query"
import LeaderMessage from "./LeaderMessage"

interface LeaderMessagesProps {}
const LeaderMessages: FunctionComponent<LeaderMessagesProps> = ({}) => {
    const gameId = useGameId()
    const queryClient = useQueryClient()
    const { data: faction } = useFactions()
    const ownFaction = faction?.find((f) => f.my_faction)

    return (
        <SimpleGrid p="2" columns={[1, 1, 2, 3]}>
            <LeaderMessage leaderRole={LeaderRole.Leader} message={ownFaction?.details?.messages.leader!} />
            <LeaderMessage leaderRole={LeaderRole.General} message={ownFaction?.details?.messages.general!} />
            <LeaderMessage leaderRole={LeaderRole.Diplomat} message={ownFaction?.details?.messages.diplomat!} />
        </SimpleGrid>
    )
}

export default LeaderMessages
